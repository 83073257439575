<template>
  <v-container>
    <h2 class="mb-4">Determining the Percent Yield of a Simple Synthesis - Results</h2>

    <p class="mb-3">
      Fill in the table below using the data you collected throughout the experiment and any
      required calculations.
    </p>

    <v-simple-table style="max-width: 591px" class="ml-n4">
      <thead>
        <tr>
          <td>
            <stemble-latex content="$\text{Property}$" />
          </td>
          <td style="text-align: center">
            <stemble-latex content="$\text{Value}$" />
          </td>
        </tr>
        <tr>
          <td>a) Volume of 0.100 M <chemical-latex content="KI" /> (mL)</td>
          <td>
            <calculation-input
              v-model="inputs.volKI"
              class="centered-input my-1"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td>b) Volume of 0.200 M <chemical-latex content="Pb(NO3)2" /> (mL)</td>
          <td>
            <calculation-input
              v-model="inputs.volPbNO32"
              class="centered-input my-1"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td>c) Moles of <chemical-latex content="KI" /> (mol)</td>
          <td>
            <calculation-input
              v-model="inputs.molKI"
              class="centered-input my-1"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td>d) Moles of <chemical-latex content="Pb(NO3)2" /> (mol)</td>
          <td>
            <calculation-input
              v-model="inputs.molPbNO32"
              class="centered-input my-1"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td>e) Theoretical yield of <chemical-latex content="PbI2" /> (g)</td>
          <td>
            <calculation-input
              v-model="inputs.theoMassPbI2"
              class="centered-input my-1"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td>f) Actual mass of <chemical-latex content="PbI2" /> (g)</td>
          <td>
            <calculation-input
              v-model="inputs.massPbI2"
              class="centered-input my-1"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td>g) Percent Yield (%)</td>
          <td>
            <calculation-input
              v-model="inputs.pctYield"
              class="centered-input my-1"
              :disabled="!allowEditing"
            />
          </td>
        </tr>
      </thead>
    </v-simple-table>
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'SimpleSynthesisPercentYieldReportSheet',
  components: {
    ChemicalLatex,
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        volKI: null,
        volPbNO32: null,
        molKI: null,
        molPbNO32: null,
        theoMassPbI2: null,
        massPbI2: null,
        pctYield: null,
      },
    };
  },
};
</script>

<style>
input[type='number'] {
  -moz-appearance: textfield; /*For FireFox*/

  &::-webkit-inner-spin-button {
    /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
